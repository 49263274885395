import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Чат хэрэглүүр",
  "description": null,
  "author": "OTC help",
  "category": "chat",
  "date": "2022-04-08T00:00:00.000Z",
  "featured": true,
  "tags": [],
  "order": 1
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Чат хэрэглүүр`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Үндсэн цэсний `}<strong parentName="p">{`[Chat]`}</strong>{` хэсэг нь оролцогчид хэлцэл хийхэд зориулсан хэрэглүүр юм.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`[Chat]`}{`-`}{`[Search users]`}</strong>{` хэсэгт чат илгээх оролцогчийг хайж сонгосон оролцогчруу текст, файл илгээх мөн `}<strong parentName="p">{`[Propose a Deal]`}</strong>{` товчийг даран хэлцэл хийх санал илгээх, санал хүлээн авах боломжтой.`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      